import { Route, Routes } from 'react-router-dom';
import Home from './components/Home';
import About from './components/About';
import Contact from './components/Contact';
import Layout from './components/Layout';
import Portfolio from './components/Portfolio';
import Dashboard from './components/Dashboard';
import ErrorBoundary from './ErrorBoundary'; // Import ErrorBoundary
import './App.scss';

function App() {
  return (
    <>
      <Routes>
        <Route path="/" element={<Layout />}>
          <Route index element={
            <ErrorBoundary><Home /></ErrorBoundary>
          } />
          <Route path="about" element={
            <ErrorBoundary><About /></ErrorBoundary>
          } />
          <Route path="/contact" element={
            <ErrorBoundary><Contact /></ErrorBoundary>
          } />
          <Route path="/portfolio" element={
            <ErrorBoundary><Portfolio /></ErrorBoundary>
          } />
          <Route path="/dashboard" element={
            <ErrorBoundary><Dashboard /></ErrorBoundary>
          } />
        </Route>
      </Routes>
    </>
  )
}

export default App;
