import React, { useEffect, useState, useRef } from 'react';
import { collection, getDocs, addDoc } from 'firebase/firestore';
import { ref, uploadBytes, getDownloadURL } from 'firebase/storage';
import { auth, storage, db } from '../../firebase';

const Home = () => {
    const form = useRef();
    const [portfolioItems, setPortfolioItems] = useState([]);

    useEffect(() => {
        fetchPortfolioItems();
    }, []);

    const fetchPortfolioItems = async () => {
        try {
            const querySnapshot = await getDocs(collection(db, 'portfolio'));
            const items = querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
            setPortfolioItems(items);
        } catch (error) {
            console.error("Error fetching portfolio items:", error);
        }
    };

    const submitPortfolio = (e) => {
        e.preventDefault();
        const name = form.current[0]?.value;
        const description = form.current[1]?.value;
        const url = form.current[2]?.value;
        const image = form.current[3]?.files[0];

        if (image) {
            const storageRef = ref(storage, `portfolio/${image.name}`);
            uploadBytes(storageRef, image).then(
                (snapshot) => {
                    getDownloadURL(snapshot.ref).then((downloadUrl) => {
                        savePortfolio({
                            name,
                            description,
                            url,
                            image: downloadUrl
                        });
                    });
                }
            ).catch((error) => {
                console.error("Error uploading image:", error);
                savePortfolio({
                    name,
                    description,
                    url,
                    image: null
                });
            });
        } else {
            savePortfolio({
                name,
                description,
                url,
                image: null
            });
        }
    };

    const savePortfolio = async (portfolio) => {
        try {
            await addDoc(collection(db, 'portfolio'), portfolio);
            form.current.reset(); // Reset the form
            fetchPortfolioItems(); // Fetch the updated list
        } catch (error) {
            console.error("Error adding portfolio item:", error);
            alert('Failed to add portfolio');
        }
    };

    return (
        <div className="dashboard">
            <form ref={form} onSubmit={submitPortfolio}>
                <p><input type="text" placeholder="Name" /></p>
                <p><textarea placeholder="Description" /></p>
                <p><input type="text" placeholder="URL" /></p>
                <p><input type="file" placeholder="Image" /></p>
                <button type="submit">Submit</button>
                <button type="button" onClick={() => auth.signOut()}>Sign out</button>
            </form>

            <div>
                {portfolioItems.map(item => (
                    <div key={item.id}>
                        <h3>{item.name}</h3>
                        <p>{item.description}</p>
                        <a href={item.url} target="_blank" rel="noopener noreferrer">Project Link</a>
                        {item.image && <img src={item.image} alt={item.name} style={{ maxWidth: '200px' }} />}
                    </div>
                ))}
            </div>
        </div>
    );
}

export default Home;
