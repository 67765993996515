
import { initializeApp } from "firebase/app";
import { getFirestore } from 'firebase/firestore';
import { getAuth, GoogleAuthProvider, signInWithPopup } from "firebase/auth";
import { getStorage } from 'firebase/storage';


const firebaseConfig = {
  apiKey: "AIzaSyBt6D1Urxx2ePoEov-RaDGGzeBsv_vNfcw",
  authDomain: "portfolio-68d29.firebaseapp.com",
  projectId: "portfolio-68d29",
  storageBucket: "portfolio-68d29.appspot.com",
  messagingSenderId: "685546521364",
  appId: "1:685546521364:web:5bf135b9385dcd9c04f801",
  measurementId: "G-YNQYH4SQ3P"
};


const app = initializeApp(firebaseConfig);
export const auth = getAuth(app);
const provider = new GoogleAuthProvider();
export const db = getFirestore(app);
export const storage = getStorage(app);


export const signInWithGoogle = () => signInWithPopup(auth, provider);