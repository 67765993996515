import React from 'react';
import { signInWithGoogle } from '../../firebase'; // adjust the path as necessary

const Login = () => {
    return (
        <div className="dashboard">
            <button onClick={signInWithGoogle}>
                Sign in with Google
            </button>
        </div>
    );
}

export default Login;